import { atom, GetRecoilValue, selector, selectorFamily } from 'recoil'
import _ from 'lodash'
import { feature as turfFeature } from '@turf/helpers'

import { validateGeojson } from 'helpers/geojson'
import { SuGraphqlError } from 'helpers/graphql'
import { sessionUserGroupState } from 'recoilStore/userStore'
import { getAssetsByType } from '../services/api/assetType'
import { EMISSION_OBSERVATION_ATTRIBUTION_TYPES } from '../constants/emissionObservation'
import { EmissionObservationAttributionType } from '../components/AttributeDetectionModal/types'
import { DEFAULT_ASSET_EXTERNAL_ID } from '../constants/common'

type AssetQueryParams = Partial<{
  properties: string[]
  assetsOmitFields: string[]
}>

export const DEFAULT_ASSET_NAME = 'Unnamed'

const sortAssetOptionsByLabel = (options: { label: string }[]) =>
  _.sortBy(options, { label: DEFAULT_ASSET_NAME })

export const assetExternalIdState = atom({
  key: 'assetExternalIdState',
  default: DEFAULT_ASSET_EXTERNAL_ID,
})

const fetchAssetsByType = async ({
  get,
  assetType,
  queryParams,
}: {
  get: GetRecoilValue
  assetType: EmissionObservationAttributionType
  queryParams?: AssetQueryParams
}) => {
  const sessionGroup = get(sessionUserGroupState)
  const { data, error } = await getAssetsByType(assetType)({
    assetsOmitFields: ['geometryJson', 'group'],
    group: sessionGroup,
    ...queryParams,
  })

  if (error && _.isEmpty(data)) {
    throw new SuGraphqlError({
      error: `Failed to fetch asset ${assetType}s: ${error}`,
    })
  }

  return data
}

export const assetSitesListQuery = selectorFamily({
  key: 'assetSitesListQuery',
  get:
    () =>
    async ({ get }) => {
      const assetExternalId = get(assetExternalIdState)
      return fetchAssetsByType({
        get,
        assetType: EMISSION_OBSERVATION_ATTRIBUTION_TYPES.Site,
        queryParams: {
          assetsOmitFields: [],
          properties: _.compact([assetExternalId, 'name', 'isDAC', 'id']),
        },
      })
    },
})

export const assetSitesOptionsState = selector({
  key: 'assetSitesOptionsState',
  get: ({ get }) => {
    const list = get(assetSitesListQuery({}))
    const assetExternalId = get(assetExternalIdState)
    return sortAssetOptionsByLabel(
      _.map(list, ({ properties, assetReference, geometryJson, group }) => {
        const siteId = properties?.[assetExternalId]
        const label = _.trim(properties?.name) || DEFAULT_ASSET_NAME
        const observation = turfFeature(geometryJson)
        return {
          properties,
          label,
          siteId,
          group,
          labelExtras: {
            description: siteId || label,
          },
          value: assetReference,
          ...(validateGeojson(observation) && {
            observation,
          }),
        }
      })
    )
  },
})

export const assetWellsListQuery = selectorFamily({
  key: 'assetWellsListQuery',
  get:
    () =>
    async ({ get }) => {
      const assetExternalId = get(assetExternalIdState)
      return fetchAssetsByType({
        get,
        assetType: EMISSION_OBSERVATION_ATTRIBUTION_TYPES.Well,
        queryParams: { properties: _.compact(['name', assetExternalId]) },
      })
    },
})

export const assetPipelinesListQuery = selectorFamily({
  key: 'assetWellsListQuery',
  get:
    () =>
    async ({ get }) => {
      return fetchAssetsByType({
        get,
        assetType: EMISSION_OBSERVATION_ATTRIBUTION_TYPES.Pipeline,
        queryParams: { properties: ['name'] },
      })
    },
})

export const assetWellsOptionsState = selector({
  key: 'assetWellsOptionsState',
  get: ({ get }) => {
    const assetExternalId = get(assetExternalIdState)
    const list = get(assetWellsListQuery({}))
    return sortAssetOptionsByLabel(
      _.map(list, ({ assetReference, properties }) => {
        return {
          value: assetReference,
          label:
            _.trim(properties?.name) ||
            properties?.[assetExternalId] ||
            DEFAULT_ASSET_NAME,
        }
      })
    )
  },
})

export const assetPipelinesOptionsState = selector({
  key: 'assetPipelinesOptionsState',
  get: ({ get }) => {
    const list = get(assetPipelinesListQuery({}))

    return sortAssetOptionsByLabel(
      _.map(list, ({ assetReference, properties }) => {
        const { name } = properties || {}
        return {
          value: assetReference,
          label: _.trim(name) || DEFAULT_ASSET_NAME,
        }
      })
    )
  },
})

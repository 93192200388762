import { selectorFamily } from 'recoil'
import _ from 'lodash'
import { gql, useApolloClient } from '@apollo/client'
import { sessionUserGroupState } from 'recoilStore/userStore'

import { getRelayEdgesData } from 'helpers/graphql'
import { assetExternalIdState } from './assetTypeStore'
import { AssetEquipment } from '../types/graphql'

const GET_ASSET_EQUIPMENTS = gql`
  query AssetEquipmentsAll($filter: AssetEquipmentFilter, $properties: [ID!]) {
    assetEquipments {
      all(filter: $filter) {
        edges {
          node {
            assetReference
            id
            properties(properties: $properties)
            equipmentComponents {
              name
            }
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`

export const siteEquipmentsQuery = selectorFamily({
  key: 'SiteEquipments',
  get:
    ({ parentAssetReference }: { parentAssetReference?: string }) =>
    async ({ get }) => {
      if (!parentAssetReference) return []

      const assetExternalId = get(assetExternalIdState)
      const sessionGroup = get(sessionUserGroupState)
      const variables = {
        filter: {
          ...(sessionGroup ? { group: sessionGroup } : {}),
          propertyFilters: [
            {
              property: 'isAttributable',
              valueJson: true,
            },
          ],
          parentAssetReference,
        },
        properties: _.compact(['name', 'isAttributable', assetExternalId]),
      }
      const client = useApolloClient()
      const { data } = await client.query({
        query: GET_ASSET_EQUIPMENTS,
        variables,
      })

      return _.map(
        getRelayEdgesData(data, ['assetEquipments', 'all']),
        ({
          assetReference,
          properties,
          equipmentComponents,
          ...rest
        }: AssetEquipment) => ({
          ...rest,
          properties,
          value: assetReference,
          label: properties.name,
          components: _.map(equipmentComponents, component => {
            return {
              equipmentComponentsName: component.name,
            }
          }),
          labelExtras: {
            description: properties[assetExternalId],
          },
        })
      )
    },
})
